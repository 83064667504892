



























































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Indicator } from "@/models/Indicator";
import {
  INDICATOR_REQUEST,
  INDICATOR_UPDATE,
  INDICATOR_CREATE,
  INDICATOR_DELETE,
  INDICATOR_UPDATED,
  INDICATOR_LOADED,
  INDICATOR_VERIFY,
} from "@/store/actions/indicator";
@Component({})
export default class Indicators extends Vue {
  private indicators: Indicator[] = [];
  private form: Indicator = new Indicator();
  private indicatorsHeaders = [
    {
      text: "Имя",
      value: "indicator_name",
      align: "start",
      width: "35%",
    },
    {
      text: "Название",
      value: "indicator_title",
      align: "start",
      width: "35%",
    },
    {
      text: "Ед. измерения",
      value: "indicator_units",
      align: "start",
      width: "10%",
    },
    {
      text: "Верифицикация",
      value: "verified",
      align: "start",
      width: "5%",
    },
    {
      text: "Региональные паспорта",
      value: "regional_passports",
      align: "center",
      sortable: false,
      width: "5%",
    },
    {
      text: "Федеральный паспорт",
      value: "federal_passport",
      align: "center",
      sortable: false,
      width: "1%",
    },
    {
      text: "По регионам",
      value: "region",
      align: "center",
      sortable: false,
      width: "1%",
    },
    {
      text: "Действия",
      value: "actions",
      align: "center",
      sortable: false,
      width: "10%",
    },
  ];
  private loading: boolean = false;
  private createDialog: boolean = false;
  private isUpdate: boolean = false;
  private itemId: number = 0;
  //Snackbar
  private messageText = "Не удалось выполнить";
  private messageColor = "red";
  private message = false;
  private search = "";
  private itemToDelete: Indicator = new Indicator();
  private deleteDialog: boolean = false;
  private deleteSnackbar: boolean = false;
  private deleteDisabled: boolean = false;
  //verify
  private verifyingItems: number[] = [];
  private verifier: boolean = false;
  //options
  private tablePage = 1;
  private tablePageCount = 0;
  private itemsPerPage = 15;
  //Initialise methods
  private initIfReady() {
    //return true if started to init self.
    let needReload = [] as boolean[];
    needReload.push(
      this.needReload(
        this.$store.getters.INDICATOR_TABLE,
        this.$store.getters.INDICATOR_LOADED
      )
    );

    for (let i = 0; i < needReload.length; i++) {
      if (needReload[i] == true) {
        return false;
      }
    }
    console.log("TRUE INIT SELF");
    this.initSelf();
    return true;
  }
  private needReload(table, updated) {
    /*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
    if (table == null || !updated) return true;
    return false;
  }
  private tryToInitSelf() {
    this.loading = true;
    //const data = this.getParams();
    if (this.initIfReady()) return;

    if (
      this.needReload(
        this.$store.getters.INDICATOR_TABLE,
        this.$store.getters.INDICATOR_LOADED
      )
    ) {
      this.$store.dispatch(INDICATOR_REQUEST).then(() => {
        this.initIfReady();
      });
    }
  }
  private initSelf() {
    console.log("INIT SELF");
    this.indicators = this.$store.getters.INDICATOR_TABLE.data;
    if (localStorage.getItem("role") == "Verifer") {
      this.verifier = true;
    }
    this.loading = false;
    console.log("loading");
  }
  //Methods
  private isDeleting(id) {
    if (this.itemToDelete.id == id) return true;
    return false;
  }
  private cleanDeleting() {
    this.itemToDelete = new Indicator();
  }
  private toCreate() {
    this.isUpdate = false;
    this.createDialog = true;
  }
  private toEdit(item) {
    this.isUpdate = true;
    this.form.indicator_name = item.indicator_name;
    this.form.indicator_title = item.indicator_title;
    this.form.indicator_units = item.indicator_units;
    this.itemId = item.id;
    this.createDialog = true;
  }
  private toFederal(item){
    var data = JSON.stringify(item.federal_passport);
    localStorage.setItem("federal", data);
    localStorage.setItem("federalIndicatorTitle", item.indicator_title);
    this.$router.push({ name: "IndicatorsFederalForm", params: { id: item.id } });
  }
  private toRegional(item){
    //var data = JSON.stringify(item.regional_passports);
    const parsed = JSON.stringify(item);
    localStorage.setItem("currentIndicator", parsed);
    //localStorage.setItem("regional", data);
    localStorage.setItem("regionalIndicatorTitle", item.indicator_title);
    this.$router.push({ name: "IndicatorsRegionalPassports", params: { id: item.id } });
  }
  private toRegion(item) {
    const parsed = JSON.stringify(item);
    localStorage.setItem("indicatorToEdit", parsed);
    localStorage.setItem("indicatorTitle", item.indicator_title);
    this.$router.push({ name: "IndicatorsRegion", params: { id: item.id } });
  }
  private verify(item) {
    let v = item.verified as boolean;
    v = !v;
    let data = {
      id: +item.id,
      params: {
        v: v,
      },
    };
    this.verifyingItems.push(+item.id);
    this.$store.dispatch(INDICATOR_VERIFY, data).then(() => {
      this.tryToInitSelf();
      for (let i = 0; i < this.verifyingItems.length; i++) {
        if (+item.id == this.verifyingItems[i]) {
          this.verifyingItems.splice(i, 1);
        }
      }
    });
  }
  private isVerifying(id) {
    if (!this.verifier) return true;
    for (let i = 0; i < this.verifyingItems.length; i++) {
      if (id == this.verifyingItems[i]) {
        return true;
      }
    }
  }
  private addItem() {
    var converted = new FormData();
    Object.keys(this.form).forEach((key) => {
      if (typeof this.form[key] !== "undefined")
        converted.append(key, this.form[key]);
      else console.log("undefined:", key);
    });
    this.$store
      .dispatch(INDICATOR_CREATE, converted)
      .then(() => {
        this.messageText =
          "Показатель " + this.form.indicator_name + " добавлен.";
        this.messageColor = "green";
        this.tryToInitSelf();
      })
      .catch(() => {
        this.messageText = "Не удалось выполнить";
        this.messageColor = "red";
      })
      .finally(() => {
        this.message = true;
      });
  }
  private updateItem() {
    var converted = new FormData();
    Object.keys(this.form).forEach((key) => {
      if (typeof this.form[key] !== "undefined")
        converted.append(key, this.form[key]);
      else console.log("undefined:", key);
    });
    var data = {
      id: this.itemId,
      data: converted,
    };
    this.$store
      .dispatch(INDICATOR_UPDATE, data)
      .then(() => {
        this.tryToInitSelf();
      })
      .then(() => {
        this.messageText =
          "Показатель " + this.form.indicator_name + " изменен.";
        this.messageColor = "green";
        this.tryToInitSelf();
      })
      .catch(() => {
        this.messageText = "Не удалось выполнить";
        this.messageColor = "red";
      })
      .finally(() => {
        this.message = true;
      });
  }
  // private deleteItem(id) {
  //   this.$store.dispatch(INDICATOR_DELETE, id).then(() => {
  //     this.tryToInitSelf();
  //   });
  // }

  private deleteItem(item: Indicator, confirmation: boolean) {
    this.itemToDelete = item;
    if (confirmation) {
      this.deleteDialog = true;
    } else {
      console.log(item);
      console.log("Delete!");
      this.$store.dispatch(INDICATOR_DELETE, item.id).then(() => {
        this.tryToInitSelf();
        this.deleteSnackbar = true;
        this.itemToDelete = new Indicator();
        this.deleteDisabled = false;
      });
      this.deleteDialog = false;
    }
  }
  //Hooks
  private mounted() {
    this.tryToInitSelf();
  }
}
